import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksComponent } from './tasks/tasks.component';
import {SharedModule} from '../shared/shared.module';
import {TasksRoutingModule} from './tasks-routing.module';
import {
  MbscButtonModule,
  MbscEventcalendarModule,
  MbscFormsModule,
  MbscModule,
  MbscSegmentedModule
} from '@mobiscroll/angular';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzTagModule} from 'ng-zorro-antd/tag';
import { CalendarSettingsComponent } from './calendar-settings/calendar-settings.component';
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzFormModule} from "ng-zorro-antd/form";
import {ReactiveFormsModule} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzAnchorModule} from "ng-zorro-antd/anchor";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzListModule} from "ng-zorro-antd/list";
import {NzTabsModule} from "ng-zorro-antd/tabs";
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzUploadModule} from "ng-zorro-antd/upload";
import { TaskImportComponent } from './task-import/task-import.component';
import {NzStepsModule} from "ng-zorro-antd/steps";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';




@NgModule({
	declarations: [TasksComponent, CalendarSettingsComponent, TaskImportComponent],
	exports: [
	],
  imports: [
    CommonModule,
    SharedModule,
    TasksRoutingModule,
    MbscEventcalendarModule,
    MbscSegmentedModule,
    MbscButtonModule,
    MbscButtonModule,
    MbscFormsModule,
    MbscModule,
    MbscModule,
    NzCardModule,
    NzGridModule,
    NzButtonModule,
    NzAlertModule,
    NzCheckboxModule,
    NzDrawerModule,
    NzSpinModule,
    NzIconModule,
    NzBadgeModule,
    NzPopoverModule,
    NzTagModule,
    NzSelectModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzToolTipModule,
    NzAnchorModule,
    NzDropDownModule,
    NzMenuModule,
    NzProgressModule,
    NzTableModule,
    NzDividerModule,
    NzListModule,
    NzTabsModule,
    NzAvatarModule,
    NzUploadModule,
    NzStepsModule,
    NzModalModule,
    NzSwitchModule,
    NzCollapseModule
  ]
})
export class TasksModule { }
