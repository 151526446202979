@import '../../../assets/scss/variables';

// Event Style

.mbsc-schedule-event {
  min-height: 25px;

}

.mbsc-timeline-event {
    min-width: 50px;
    max-height: 50px;
}

.md-custom-event-cont {
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  background: #5ac8fa;
  border-left: 5px solid #888888;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 0 3px 3px 0;

}

.md-custom-event-wrapper {
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  //min-height: 50px;
  box-sizing: border-box;
  transition: background 0.15s ease-in-out;

}

.mbsc-schedule-event-hover .md-custom-event-cont .md-custom-event-wrapper {
  background: rgba(255, 255, 255, 0.3);
}

.mbsc-schedule-event-active .md-custom-event-cont .md-custom-event-wrapper {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.mbsc-schedule-event-hover .md-custom-event-allday-title {
  opacity: 0.8;
}

.mbsc-schedule-event-active .md-custom-event-allday-title {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.mbsc-timeline-event {
  .md-custom-event-details {
    left: 196px;
  }
}


.md-custom-event-details {
  padding: 1px 5px;
  color: initial;
  font-weight: 500;
  top: 2px;

  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  position: sticky;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.md-custom-event-title {
  color: initial;
  font-weight: bold !important;
}

.md-custom-event-time {
  font-size: 10px;
  color: #666;
  padding-top: 3px;
}

// Event Style End

[ng-reflect-type="day"] .mbsc-schedule-col-width {
  min-width: 200px;
}

[ng-reflect-type="week"] .mbsc-schedule-col-width {
  min-width: 70px;
}

.md-switching-view-cont .mbsc-segmented {
  max-width: 300px;
  margin: 0 auto;
}

.cal-header-picker {
  flex: 1 0 auto;
}

.cal-header-nav {
  width: 200px;
}

.external-event-task {
  color: #fff;
  background-color: #687fa4;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: move;

  a {
    color: #d1e8ff;
  }
}


.full-height-container {
  height: calc(100vh - #{$header-height}) !important;
  min-height: 800px;
  overflow: auto;
}


.floating-btn {

  position: absolute;
  bottom: 15px;
  right: 30px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0px 5px 20px 0px rgba(0,0,0,0.15);
}

.hover {
  transition: all .2s ease-in-out;
  opacity: .6;
  &:hover {
    transform: scale(0.98);
    opacity: 1;
  }
}


.waste-ghost-icon {
  position: absolute;
  right: 22px;
  bottom: 2px;
  opacity: 0.2;
  font-size: 47px;
}

.in-out-tag {
  position: absolute;
  color: #FFFFFF;
  right: 8px;
  bottom: 8px;
  border-radius: 10px;
  padding: 1px 5px;
  font-size: 12px;

  &.in {
    background-color: #4d8d64;
  }

  &.out {
    background-color: #700200;
  }
}
